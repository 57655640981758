/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}
* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
body {
  margin: 0;
  padding: 0;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#loading-image {
  height: 75px;
  margin-bottom: 24px;
}
#loading-container {
  background-color: #001529;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#login-background {
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
  background-color: #001529;
  overflow-y: scroll;
}
#login-card {
  width: 300px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}
#login-card-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16;
}
#login-logo {
  height: 75px;
  margin-top: -50px;
}
#login-application-name {
  font-weight: bold;
  font-size: 2em;
  line-height: 2em;
  text-align: center;
  display: block;
}
#header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
}
#header-compact-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
#header-logo {
  width: 48px;
  margin-right: 16px;
}
@media (max-width: 1100px) {
  #header-logo {
    width: 36px;
    margin-right: 8px;
  }
}
@media (max-width: 600px) {
  #header-logo {
    width: 36px;
  }
}
#header-application-name {
  font-weight: bold;
  color: #fff;
  font-size: 2em;
  line-height: 2em;
}
@media (max-width: 1100px) {
  #header-application-name {
    font-size: 1.5em;
    line-height: 1.5em;
    margin-right: 16px;
  }
}
#header-customer-name {
  font-weight: 400;
  font-size: 1.2em;
  color: #fff;
  line-height: 1.2em;
  margin-left: 12px;
}
.header-container {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: row;
}
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}
#map-focus-indicator-container {
  position: absolute;
  bottom: 48px;
  left: 24px;
  right: 24px;
  z-index: 1;
  display: flex;
  justify-content: center;
  transition: margin 300ms;
}
#map-attribution-container {
  position: absolute;
  bottom: 12px;
  right: 12px;
  left: 12px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 600px) {
  #map-attribution-container {
    justify-content: center;
  }
}
#map-attribution {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 4px 8px;
  border-radius: 4px;
}
.map-attribution-link {
  font-size: 0.85em;
}
.map-pulsating-circle-container {
  position: relative;
  width: 2.5em;
  height: 2.5em;
}
.map-pulsating-circle-dot {
  width: 100%;
  height: 100%;
  border-radius: 1.25em;
  background-color: #ff4d4f;
  border-color: #fff;
  border-width: 0.15em;
  border-style: solid;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
.map-pulsating-circle-ring {
  position: absolute;
  top: -1.25em;
  left: -1.25em;
  width: 200%;
  height: 200%;
  border-radius: 2.5em;
  background-color: #ff4d4f;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
.map-popup-container {
  flex: 1;
  display: flex;
  margin: 0px 0px -5px 0px;
}
.map-popup-driver-name {
  font-size: 1.25em;
  font-weight: 500;
  margin-top: 4px;
  white-space: nowrap;
}
#map-drawer {
  position: absolute;
}
#map-drawer-layout {
  width: 100%;
  height: 100%;
}
#map-drawer-header {
  background-color: #fff;
  line-height: 0;
  height: auto;
  padding: 24px 24px 0px 0px;
}
#map-drawer-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.map-drawer-segment-group {
  width: 100%;
}
.map-drawer-segment-item {
  width: 50%;
  text-align: center;
}
#live-header-container {
  padding: 0px 24px 24px 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
#live-refresh-badge {
  margin-top: 16px;
  width: 100%;
  font-size: 1.05em;
  padding: 4px;
  text-align: center;
}
#live-filter-radio-group {
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.live-filter-radio-item {
  width: 33%;
  text-align: center;
}
#live-driver-list-container {
  flex: 1;
}
#live-action-bar-top {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}
#live-action-bar-bottom {
  display: flex;
  flex-direction: row;
}
#live-action-bar-bottom-container {
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px 0px;
}
#live-badge {
  width: 100%;
}
.live-settings-container {
  display: flex;
  flex-direction: column;
}
.live-settings-select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.live-settings-select-description {
  margin-right: 8px;
}
#historical-container {
  padding: 0px 24px 0px 24px;
}
.historical-driver-option {
  font-size: 1.1em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline;
  margin: 0px;
  align-self: center;
}
.driver-list-item {
  padding: 16px 16px 16px 0px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.8s;
  display: flex;
  align-items: center;
  width: 100%;
}
.driver-list-item:hover {
  background-color: rgba(60, 90, 100, 0.04);
}
.driver-list-item-status {
  border-radius: 5px;
  height: 10px;
  width: 10px;
  margin-right: 16px;
}
.driver-list-item-info-container {
  flex: 1;
  min-width: 0px;
}
.driver-list-item-timestamp {
  margin: 0px;
  font-size: 1em;
}
.driver-list-item-name {
  margin: 0px;
  font-size: 1.1em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.driver-list-item-phone {
  margin: 0px;
  display: inline-block;
  font-size: 1em;
}
